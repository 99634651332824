import { loginUser, getUserInfo } from '@/api/sys'
import { getItem, setItem, removeItem } from '@/utils/storage'
import { TokenKey } from '@/constant'
import router from '@/router'
export default {
  namespaced: true,
  state: () => ({
    // 登录之后的 token
    token: getItem(TokenKey) || '',
    userInfo: {}, // 用户信息
  }),
  mutations: {
    /**
     * 保存token
     */
    setToken(state, newToken) {
      state.token = newToken
      setItem(TokenKey, newToken)
    },
    // 清除token
    removeToken(state) {
      state.token = null
      removeItem(TokenKey)
    },
    setUserInfo(state, result) {
      // 更新一个对象
      state.userInfo = result // 这样是响应式
      // state.userInfo = {...result}  // 这样也是响应式 属于浅拷贝
      // state.userInfo['username'] = result // 这样才不是响应式
    },
    // 删除用户信息
    removeUserInfo(state) {
      state.userInfo = {}
    }
  },
  actions: {
    /**
     * 1.登录
     */
    async login(context, payload) {
      // 1.1 调用登录接口
      const data = await loginUser(payload)
      // 1.2. 保存token
      context.commit('setToken', data.session)
      // 1.3. 存储用户信息
    },
    /**
     * 获取用户信息
     */
    async getUserInfo(context) {
      const result = await getUserInfo()
      console.log(result, '用户信息')
      context.commit('setUserInfo', result)
    },
    /**
     * 2.退出登陆
     */
    logout(context) {
      // 2.1 清空token
      // context.commit('setToken', '')
      context.commit('removeToken')
      // 2.2 清空用户信息
      context.commit('removeUserInfo')
      // 2.3 退出登录之后，重新刷新下页面，因为对于前台项目而言，用户是否登录（是否为 VIP）看到的数据可能不同
      // location.reload()
      router.push('/login')
    }
  }
}
