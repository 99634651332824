export default {
  namespaced: true,
  state: () => ({
    ScanText: '',
    goodsList: []
  }),
  mutations: {
    scanCodeSuccess(state, newScanText) {
      state.ScanText = newScanText
    },
    setGoodsList(state, newList) {
      state.goodsList = [...state.goodsList, ...newList]
    },
    removeGoodListItem(state, index) {
      state.goodsList.splice(index, 1)
    }
  }
}
