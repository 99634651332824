import router from '@/router'
import store from '@/store'

// 定义白名单 所有不受权限控制的页面
const whiteList = ['/login', '/404']
/**
 * 路由前置守卫
 */
router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    // 1.用户已登录，则不允许进入login
    if (to.path === '/login') {
      next('/') // 跳转到主页
    } else {
      next()
    }
    // next()
  } else {
    // 没有token的情况下
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      // 2.用户未登录，只允许进入login
      next('/login') // 跳转登陆页
    }
  }
})