import axios from 'axios'
import store from '@/store'
import { showNotify } from 'vant'
const service = axios.create({
  baseURL: "http://api.jewellery.jizui.top/frontend",
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      // 如果token存在 注入token
      config.headers.session = store.getters.token
    }
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data
    console.log(response.data, '返回的数据')
    if (code == 200) {
      return data
    } else {
      // 提示报错信息
      if (code === 501) {
        // 登出操作 删除token
        store.dispatch('user/logout')
      }
      showNotify({
        type: 'warning', color: '#ad0000',
        background: '#ffe1e1', message
      })
      return Promise.reject(new Error(message))
    }
  },
  (error) => {
    console.log(error, '错误信息')
    // 被动退出 处理token超时问题
    // if (
    //   error.response &&
    //   error.response.data &&
    //   error.response.data.code === 501
    // ) {
    //   // TODO: token超时
    //   store.dispatch('user/logout')
    // }
    // // TODO:提示错误信息
    // showNotify({ type: 'warning', message: error.message })
    return Promise.reject(error)
  }
)
export default service
