import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/home/index.vue')
  // },
  {
    path: '/',
    name: 'shop',
    component: () => import('@/views/shop/index.vue'),
    meta: { title: 'xx珠宝' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/transferHistory',
    name: 'transferHistory',
    component: () => import('@/views/transfer-history/index.vue'),
    meta: { title: '调货历史' }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/change-password/index.vue')
  },
  {
    path: '/transferDetails/:id',
    name: 'transferDetails',
    component: () => import('@/views/transfer-details/index.vue')
  },
  {
    path: '/searchTransferGoods',
    name: 'searchTransferGoods',
    component: () => import('@/views/search-transfergoods/index.vue')
  },
  {
    path: '/scanCodeTransferGoods',
    name: 'scanCodeTransferGoods',
    component: () => import('@/views/scancode-transfergoods/index.vue'),
    meta: { title: '扫码调货' }
  },
  {
    name: 'scanCodePage',
    path: '/scanCodePage',
    meta: { title: '扫码页面' },
    component: () => import('@/views/scanCodePage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
