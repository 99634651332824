import request from '@/utils/request'
/**
 * 登录
 */
export const loginUser = (data) => {
  return request({
    url: '/User/login',
    method: 'POST',
    data
  })
}

/**
 * 获取用信息
 */
export const getUserInfo = () => {
  return request({
    url: '/User/detail',
    method: 'post'
  })
}

/** 获取店铺 */
export const getStoreInfo = () => {
  return request({
    url: 'Store/finds',
    method: "GET"
  })
}

/**
 * 搜索商品
 */

export const getSearchGoods = (data) => {
  return request({
    url: "goods/search_goods",
    method: "post",
    data
  })
}

/**
 * 调货确定
 */

export const confirmGoods = (data) => {
  return request({
    url: "TransferGoods/create",
    method: "post",
    data
  })
}

/**
 * 获取调货历史
 */
export const getTransferGoodHistory = () => {
  return request({
    url: "TransferGoods/finds",
    method: "post",
  })
}

/**
 * 获取调货详情
 */
export const getTransferGoodDetail = (data) => {
  return request({
    url: "TransferGoods/detail",
    method: "post",
    data
  })
}

/**
 * 修改密码
 */
export const changePassword = (data) => {
  return request({
    url: "User/edit_password",
    method: "post",
    data
  })
}