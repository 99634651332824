/**
 * 存储数据
 */
export const setItem = (key, value) => {
  localStorage.setItem(key, value)
}

/**
 * 获取数据
 */
export const getItem = (key) => {
  const data = localStorage.getItem(key)
  return data
}

/**
 * 删除数据
 */
export const removeItem = (key) => {
  localStorage.removeItem(key)
}
